/**
 * 全ページ共通処理
 */
// import "@babel/polyfill";
import 'jquery';
import $ from 'jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery.cookie';
window.$ = $
window.jQuery = $
// delighters
var Delighters = new function () {
    var t = this.dels = [], e = {
        attribute: "data-delighter",
        classNames: ["delighter", "started", "ended"],
        start: .75,
        end: .75,
        autoInit: !0
    };

    function s() {
        document.addEventListener("scroll", d);
        for (var s = document.querySelectorAll("[" + e.attribute + "]"), a = 0; a < s.length; a++) {
            var n = s[a], i = n.getAttribute(e.attribute, 2).split(";"), r = {};
            r.start = e.start, r.end = e.end;
            for (var l = 0; l < i.length; l++) {
                var o = i[l].split(":"), c = o[0], u = isNaN(1 * o[1]) ? o[1] : 1 * o[1];
                c && (r[c] = void 0 === u || u)
            }
            r.el = n, r.id = t.length, t.push(r), n.classList.add(e.classNames[0]), r.debug && (n.style.outline = "solid red 4px")
        }
        d()
    }

    function d() {
        for (var s = window.innerHeight, d = 0; d < t.length; d++) {
            var a = t[d], n = a.el.getBoundingClientRect(), i = n.top / s, r = n.bottom / s;
            a.debug && (i >= 0 && i <= 1 && (a.startLine || (a.startLine = document.createElement("div"), document.body.appendChild(a.startLine), a.startLine.style = "position:fixed;height:0;width:100%;border-bottom:dotted red 2px;top:" + 100 * a.start + "vh")), (r < a.end || i > 1) && a.startLine && (a.startLine.parentNode.removeChild(a.startLine), delete a.startLine)), i < a.start && !a.started ? (a.started = !0, a.el.classList.add(e.classNames[1])) : i > a.start && a.started && (a.started = !1, a.el.classList.remove(e.classNames[1])), r < a.end && !a.ended ? (a.ended = !0, a.el.classList.add(e.classNames[2])) : r > a.end && a.ended && (a.ended = !1, a.el.classList.remove(e.classNames[2]))
        }
    }

    document.addEventListener("DOMContentLoaded", function () {
        e.autoInit && s()
    }), this.init = s, this.config = function (t) {
        for (var s in t) e[s] = t[s]
    }
};

jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        if (ns.includes("noPreventDefault")) {
            this.addEventListener("touchstart", handle, {passive: false});
        } else {
            this.addEventListener("touchstart", handle, {passive: true});
        }
    }
};
jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        if (ns.includes("noPreventDefault")) {
            this.addEventListener("touchmove", handle, {passive: false});
        } else {
            this.addEventListener("touchmove", handle, {passive: true});
        }
    }
};

$(function () {
    // マウスイベント:hover、タッチイベントに.hover
    $('.js_h')
        .on('mouseenter touchstart', function () {
            $(this).addClass('hover');
        }).on('mouseleave touchend', function () {
        $(this).removeClass('hover');
    });

    // ページの先頭に戻るボタン
    let topBtn = $('#scroll-top');
    topBtn.hide();
    $(window).scroll(function () {
        if ($(this).scrollTop() > 80) {
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
        }
    });
    topBtn.click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
    if ($('.toggle-detectable').length > 0) {

        // on:click トグル表示をバインド
        $('.toggle-content-link').each(function () {
            $(this).on('click', function () {
                $(this).toggleClass('toggle-on');
                $("+.toggle-content", this).slideToggle();
                // return false;
            });
        });

        // when opened via external anchor link
        let urlHash = window.location.hash;
        // console.log(urlHash);
        if (urlHash) {
            const menu_items = $(urlHash).parents('.menu__item');
            menu_items
                .children('a.toggle-content-link')
                .toggleClass('toggle-on')
            ;
            // find root parents of ".menu__item", then call toggle method
            menu_items
                .children('.toggle-content')
                .slideToggle('fast')
            ;
            // let parents = $(urlHash).parents( ".menu__item" ).css("background-color", "red");
            // console.log(toggleParents);
            // $("+.toggle-content", $(urlHash)).slideToggle('fast');
            $('body,html').animate({
                scrollTop: $(urlHash).offset().top
            }, 500);
            // return false;
        }
    }

    // エリカbot windowを閉じる
    $("#erika-bot .close-btn").click(function () {
        $('#erika-bot').css('display: none;');
    });

    // Cookies 受け入れ承諾でパネル非表示
    if ($.cookie('privacy-accepted') === undefined) {
        console.log('cookie not accepted');
        $('#enableCookies').css('display', 'block');
        $('.enableCookies-agree').click(function () {
            console.log('cookie accepted');
            $('#enableCookies').css('display', 'none');
            $.cookie('privacy-accepted', 'on', {expires: 30, path: '/'});
        });
    } else {
        $('#enableCookies').css('display', 'none');
    }

    // メガメニューから検索
    // アイコンクリック
    $('div.erika_in_nav > div.input_box >input.icon_search').click(function (e) {
        // console.log($(e.target).prev('label:first').children('input:first'));
        const input = $(e.target).prev('label:first').children('input:first');
        if (input.val().length > 0){
            location.href = input.data('bot-url') + 'queried=' + encodeURIComponent(input.val());
        }
    });
    // メガメニューでエンター
    $('.erika_in_nav > div.input_box > label > input').keypress(function (e) {
        if (e.which === 13 && $(this).val().length > 0) {
            location.href = $(this).data('bot-url') + 'queried=' + encodeURIComponent($(this).val());
        }
    });
    // topの検索窓
    if ($('.question_for_erika')[0]) {
        const input = $('.question_for_erika input');
        input.keypress(function (e) {
            if (input.val().length > 0 && e.which === 13) {
                location.href = input.data('bot-url') + 'queried=' + encodeURIComponent(input.val());
            }
        });
        $('div.question_input > div.input_box > button').click(function(e){
            if (input.val().length > 0){
                location.href = input.data('bot-url') + 'queried=' + encodeURIComponent(input.val());
            }
        });
    }

    // ローカルメニュー
    const getUrl = window.location;
    const home_url = getUrl.protocol + '//' + getUrl.host + '/';
    const url = window.location.href.replace('.html', '');
    const result = url.replace(home_url, '').split('/');
    const parent_active_id = result[0].length === 0 ? '#home' : '#' + result[0];
    $(parent_active_id).addClass('active')
    result.pop();
    result.forEach(function (element) {
        element.replace('.html', '');
        const child_active_id = '#' + element;
        $('.local-menu').find(child_active_id).addClass('active')
    });

    // For google analytics
    const filetypes = /\.(pdf|doc*|jtd*|xls*)$/i;
    const imagetypes = /\.(gif|png|jpeg|jpg)$/i;
    const image_download_class = "figure-link";
    $('a').each(function () {
        const href = $(this).prop('href');
        if (href && href.match(document.domain) && (href.match(filetypes)) ||
            href && href.match(document.domain) && (href.match(imagetypes) && $(this).hasClass(image_download_class))) {
            $(this).click(function () {
                    const extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;
                    gtag(
                        'event',
                        'Download',
                        {
                            'event_category': 'Download ' + extension,
                            'event_label': decodeURIComponent(href),
                            'value': 1
                        });
                }
            );
        }
    });
});

// IE11対応: setInternalでYTのロードを待つ
if ($('script[src="https://www.youtube.com/iframe_api"]').length > 0) {

    let checkYT = setInterval(function () {
        if (YT.loaded) {
            console.log('prep player');
            // console.log($(this).data('yt-id'));

            let player;
            $('.yt-play-link').on('click', function (e) {
                // alert($(this).data('yt-id'));
                player = new YT.Player('yt-player', {
                    height: '360',
                    width: '640',
                    videoId: $(this).data('yt-id'),
                    playerVars: {
                        'html5': 1
                    },

                });
                $('#yt-modal')
                    .on('hide.bs.modal', function (e) {
                        player = null;
                        $('#yt-player-container').html('<div id="yt-player"></div>');
                    })
                    .on('show.bs.modal', function (e) {
                        var inner = $(e.relatedTarget).parent().parent();
                        var h3 = inner.find('h3').first();
                        var badge = inner.find('span.supplemet').first();
                        var span = (undefined === badge) ? '' : '<span class="' + badge.attr('class') + '">' + badge.text() + '</span>';
                        var text = inner.find('.text-excerpt');
                        $("#yt-content").html('<h3 class="modal-movie-title">' + h3.html() + span + '</h3><div class="modal-movie-body">' + text.html() + '</div>');
                    })
                    .modal('toggle', $(this));
                // e.preventDefault();
                return false;
            });

            clearInterval(checkYT);
        }
    }, 100);
}
